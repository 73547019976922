import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { loadArtccAsync } from "@vatsim-vnas/js-libs/api/data";
import { getAirportAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Artcc, TdlsConfiguration } from "@vatsim-vnas/js-libs/models/facilities";
import { Clearance } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { distinct } from "@vatsim-vnas/js-libs/utils";
import { toast } from "react-toastify";
import { ClearanceStatus } from "src/enums";
import { OpenPosition, TdlsFlightPlan } from "src/models";
import { resetUi, setHighlightedAircraftId, setSelectedAircraftId } from "src/redux";
import { getSavedOption, processResponse } from "src/utils";
import { RootState } from "../store";

const FACILITY_FILTER_LOCAL_STORAGE_NAME = "facility-filter-enabled";
const TEMP_DEP_FREQS_SESSION_STORAGE_NAME = "temp-dep-freqs";
const OVERRIDE_DEP_FREQ_SESSION_STORAGE_NAME = "override-dep-freq";
const ADD_CURRENT_FREQ_LOCAL_STORAGE_NAME = "add-current-freq";

interface TdlsState {
  facilityFilterIsEnabled: boolean;
  artccData?: Artcc;
  facilityIds?: string[];
  openPositions: OpenPosition[];
  faaIdMap: Record<string, string>;
  flightPlans: TdlsFlightPlan[];
  facilityId?: string;
  clearance?: Clearance;
  clearanceStatus?: ClearanceStatus;
  addCurrentFreqToTempDepFreqs: boolean;
  temporaryDepFreqs: string[];
  overrideDepFreq?: string;
}

const initialState: TdlsState = {
  facilityFilterIsEnabled: getSavedOption(FACILITY_FILTER_LOCAL_STORAGE_NAME, true),
  openPositions: [],
  faaIdMap: {},
  flightPlans: [],
  addCurrentFreqToTempDepFreqs: getSavedOption(ADD_CURRENT_FREQ_LOCAL_STORAGE_NAME, false),
  temporaryDepFreqs: JSON.parse(sessionStorage.getItem(TEMP_DEP_FREQS_SESSION_STORAGE_NAME) ?? "[]"),
  overrideDepFreq: sessionStorage.getItem(OVERRIDE_DEP_FREQ_SESSION_STORAGE_NAME) ?? undefined,
};

export const loadArtccData = createAsyncThunk("tdls/loadArtccData", async (_, thunkApi) => {
  const { environment, session } = (thunkApi.getState() as RootState).auth;

  if (!environment) {
    throw Error("Failed to load ARTCC data: environment is undefined");
  }

  if (!session) {
    throw Error("Failed to load ARTCC data: session is undefined");
  }

  const res = await loadArtccAsync(session.artccId);
  const artccData = processResponse(res);

  const faaIdMap: Record<string, string> = {};
  const airportPromises = artccData
    .getAllFacilities()
    .filter((f) => f.tdlsConfiguration)
    .map((f) => getAirportAsync(environment.apiBaseUrl, f.id));

  const airportRes = await Promise.all(airportPromises);
  airportRes
    .map((r) => processResponse(r))
    .forEach((a) => {
      const { faaId, icaoId } = a;
      faaIdMap[faaId] = faaId;
      if (icaoId) {
        faaIdMap[icaoId] = faaId;
      }
    });

  const facility = artccData.getFacility(session.getPrimaryFacility());

  const frequency = session.getFrequency();
  facility
    .getAllFacilities()
    .filter((f) => f.tdlsConfiguration)
    .forEach((f) => {
      f.tdlsConfiguration!.replaceVariables(frequency);
    });

  const facilityIds = facility
    .getAllFacilities()
    .filter((f) => f.tdlsConfiguration)
    .map((f) => f.id);

  if (facilityIds.length > 1) {
    facilityIds.push(facility.id);
  }

  return { artccData, faaIdMap, facilityIds: facilityIds.filter(distinct) };
});

export const setFacility = createAsyncThunk("tdls/setFacility", async (data: string, thunkApi) => {
  const { artccData } = (thunkApi.getState() as RootState).tdls;
  const { environment, session } = (thunkApi.getState() as RootState).auth;

  if (!artccData) {
    throw Error("Failed to set facility: ARTCC data is undefined");
  }

  if (!environment) {
    throw Error("Failed to set facility: environment is undefined");
  }

  if (!session) {
    throw Error("Failed to set facility: session is undefined");
  }

  const facility = artccData.getFacility(data);

  thunkApi.dispatch(resetUi());

  document.title = `vTDLS | ${facility.id}`;
  return facility.id;
});

export const deleteFlightPlan = createAsyncThunk("tdls/deleteFlightPlan", async (data: string, thunkApi) => {
  const { selectedAircraftId, highlightedAircraftId } = (thunkApi.getState() as RootState).ui;

  let clearanceReset = false;
  if (selectedAircraftId === data) {
    thunkApi.dispatch(setSelectedAircraftId(undefined));
    clearanceReset = true;
  }

  if (highlightedAircraftId === data) {
    thunkApi.dispatch(setHighlightedAircraftId(undefined));
  }

  return { aircraftId: data, clearanceReset };
});

export const addOrUpdateFlightPlan = createAsyncThunk("tdls/addOrUpdateFlightPlan", async (data: TdlsFlightPlan, thunkApi) => {
  const { selectedAircraftId, highlightedAircraftId } = (thunkApi.getState() as RootState).ui;
  const { faaIdMap, facilityId } = (thunkApi.getState() as RootState).tdls;

  let clearanceReset = false;
  if (faaIdMap[data.departure] !== facilityId) {
    if (selectedAircraftId === data.aircraftId) {
      thunkApi.dispatch(setSelectedAircraftId(undefined));
      clearanceReset = true;
    }

    if (highlightedAircraftId === data.aircraftId) {
      thunkApi.dispatch(setHighlightedAircraftId(undefined));
    }
  }

  return { flightPlan: data, clearanceReset };
});

export const clearFlightPlans = createAsyncThunk("tdls/clearFlightPlans", async (_, thunkApi) => {
  thunkApi.dispatch(setSelectedAircraftId(undefined));
  thunkApi.dispatch(setHighlightedAircraftId(undefined));
});

export const tdlsSlice = createSlice({
  name: "tdls",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      loadArtccData.fulfilled,
      (state, action: { payload: { artccData: Artcc; faaIdMap: Record<string, string>; facilityIds: string[] } }) => {
        state.artccData = action.payload.artccData;
        state.faaIdMap = action.payload.faaIdMap;
        state.facilityIds = action.payload.facilityIds;
      },
    );
    builder.addCase(loadArtccData.rejected, (_, action) => {
      toast.error(`Failed to load ARTCC data: ${action.error.message}`);
    });
    builder.addCase(setFacility.fulfilled, (state, action: { payload: string }) => {
      state.facilityId = action.payload;
      state.clearance = undefined;
      state.clearanceStatus = undefined;
    });
    builder.addCase(setFacility.rejected, (_, action) => {
      toast.error(`Failed to set facility: ${action.error.message}`);
    });
    builder.addCase(addOrUpdateFlightPlan.fulfilled, (state, action: { payload: { flightPlan: TdlsFlightPlan; clearanceReset: boolean } }) => {
      const { flightPlan } = action.payload;
      flightPlan.tdlsFacility = state.faaIdMap[flightPlan.departure];
      state.flightPlans = [...state.flightPlans.filter((i) => i.aircraftId !== flightPlan.aircraftId), flightPlan];
      if (action.payload.clearanceReset) {
        state.clearanceStatus = undefined;
      }
    });
    builder.addCase(deleteFlightPlan.fulfilled, (state, action: { payload: { aircraftId: string; clearanceReset: boolean } }) => {
      state.flightPlans = state.flightPlans.filter((fp) => fp.aircraftId !== action.payload.aircraftId);
      if (action.payload.clearanceReset) {
        state.clearanceStatus = undefined;
      }
    });
    builder.addCase(clearFlightPlans.fulfilled, (state) => {
      state.clearanceStatus = undefined;
      state.flightPlans = [];
    });
  },
  reducers: {
    setFacilityFilterIsEnabled(state, action: { payload: boolean }) {
      state.facilityFilterIsEnabled = action.payload;
      localStorage.setItem(FACILITY_FILTER_LOCAL_STORAGE_NAME, action.payload.toString());
    },
    addOrUpdateOpenPositions(state, action: { payload: OpenPosition[] }) {
      state.openPositions = [...state.openPositions.filter((p) => !action.payload.some((op) => op.id === p.id)), ...action.payload];
    },
    deleteOpenPositions(state, action: { payload: string[] }) {
      state.openPositions = state.openPositions.filter((p) => !action.payload.includes(p.id));
    },
    clearOpenPositions(state) {
      state.openPositions = [];
    },
    setClearance(state, action: { payload: Clearance | undefined }) {
      state.clearance = action.payload;
    },
    setClearanceStatus(state, action: { payload: ClearanceStatus | undefined }) {
      state.clearanceStatus = action.payload;
    },
    setAddCurrentFreqToTempDepFreqs(state, action: { payload: boolean }) {
      state.addCurrentFreqToTempDepFreqs = action.payload;
      localStorage.setItem(ADD_CURRENT_FREQ_LOCAL_STORAGE_NAME, action.payload.toString());
    },
    setTemporaryDepFreqs(state, action: { payload: string[] }) {
      state.temporaryDepFreqs = action.payload;
      sessionStorage.setItem(TEMP_DEP_FREQS_SESSION_STORAGE_NAME, JSON.stringify(state.temporaryDepFreqs));
    },
    setOverrideDepFreq(state, action: { payload: string | undefined }) {
      state.overrideDepFreq = action.payload;
      if (action.payload) {
        sessionStorage.setItem(OVERRIDE_DEP_FREQ_SESSION_STORAGE_NAME, action.payload);
      } else {
        sessionStorage.removeItem(OVERRIDE_DEP_FREQ_SESSION_STORAGE_NAME);
      }
    },
    resetTdls(state) {
      state.artccData = undefined;
      state.openPositions = [];
      state.facilityIds = [];
      state.faaIdMap = {};
      state.flightPlans = [];
      state.facilityId = undefined;
      state.clearance = undefined;
      state.clearanceStatus = undefined;
      document.title = "vTDLS";
    },
  },
});

function getTdlsConfigurations(artccData: Artcc | undefined) {
  const tdlsConfigurations = new Map<string, TdlsConfiguration>();
  if (artccData) {
    artccData
      .getAllFacilities()
      .filter((t) => t.tdlsConfiguration)
      .forEach((f) => tdlsConfigurations.set(f.id, f.tdlsConfiguration!));
  }

  return tdlsConfigurations;
}

function getHiddenFacilityIds(artccData: Artcc | undefined, facilityId: string | undefined, openPositions: OpenPosition[], filterEnabled: boolean) {
  const facilityIds: string[] = [];

  if (!artccData || !facilityId || !filterEnabled) {
    return facilityIds;
  }

  artccData
    .getFacility(facilityId)
    .getAllFacilities()
    .filter((f) => f.id !== facilityId && f.tdlsConfiguration)
    .forEach((f) => {
      openPositions
        .filter((op) => op.facilityId !== facilityId)
        .forEach((op) => {
          if (artccData.getPosition(op.positionId).controlsAirport(f.id, artccData)) {
            facilityIds.push(f.id);
          }
        });
    });

  return facilityIds.filter(distinct);
}

function getVisibleFacilityIds(artccData: Artcc | undefined, facilityId: string | undefined, hiddenFacilityIds: string[]) {
  if (!artccData || !facilityId) {
    return [];
  }

  return artccData
    .getFacility(facilityId)
    .getAllFacilities()
    .filter((f) => f.tdlsConfiguration && !hiddenFacilityIds.includes(f.id))
    .map((f) => f.id);
}

export const {
  setFacilityFilterIsEnabled,
  addOrUpdateOpenPositions,
  deleteOpenPositions,
  clearOpenPositions,
  setClearance,
  setClearanceStatus,
  setTemporaryDepFreqs,
  setAddCurrentFreqToTempDepFreqs,
  setOverrideDepFreq,
  resetTdls,
} = tdlsSlice.actions;

export const facilityFilterIsEnabledSelector = (state: RootState) => state.tdls.facilityFilterIsEnabled;
export const artccDataSelector = (state: RootState) => state.tdls.artccData;
export const tdlsConfigurationsSelector = createSelector(artccDataSelector, getTdlsConfigurations);
export const openPositionsSelector = (state: RootState) => state.tdls.openPositions;
export const flightPlansSelector = (state: RootState) => state.tdls.flightPlans;
export const facilityIdsSelector = (state: RootState) => state.tdls.facilityIds;
export const facilityIdSelector = (state: RootState) => state.tdls.facilityId;
export const hiddenFacilityIdsSelector = createSelector(
  artccDataSelector,
  facilityIdSelector,
  openPositionsSelector,
  facilityFilterIsEnabledSelector,
  getHiddenFacilityIds,
);
export const visibleFacilityIdsSelector = createSelector(artccDataSelector, facilityIdSelector, hiddenFacilityIdsSelector, getVisibleFacilityIds);
export const clearanceSelector = (state: RootState) => state.tdls.clearance;
export const clearanceStatusSelector = (state: RootState) => state.tdls.clearanceStatus;
export const addCurrentFreqToTempDepFreqsSelector = (state: RootState) => state.tdls.addCurrentFreqToTempDepFreqs;
export const temporaryDepFreqsSelector = (state: RootState) => state.tdls.temporaryDepFreqs;
export const overrideDepFreqSelector = (state: RootState) => state.tdls.overrideDepFreq;

export default tdlsSlice.reducer;
