import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { refreshTokenAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { PositionRole } from "@vatsim-vnas/js-libs/models/vnas/common";
import { Clearance, FlightPlan } from "@vatsim-vnas/js-libs/models/vnas/flight-data";
import { OpenPositionDto, PositionType, SessionInfoDto } from "@vatsim-vnas/js-libs/models/vnas/messaging";
import { tokenHasExpired } from "@vatsim-vnas/js-libs/utils";
import { plainToInstance } from "class-transformer";
import { decodeJwt } from "jose";
import React, { ReactNode, createContext, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { OpenPosition, TdlsFlightPlan } from "src/models";
import {
  addOrUpdateFlightPlan,
  addOrUpdateOpenPositions,
  artccDataSelector,
  clearFlightPlans,
  clearOpenPositions,
  deleteFlightPlan,
  deleteOpenPositions,
  endSession as endSessionInState,
  environmentSelector,
  facilityIdSelector,
  loadArtccData,
  logout,
  nasTokenSelector,
  resetTdls,
  resetUi,
  sessionSelector,
  setFsdIsConnected,
  setSession,
  setSessionIsActive,
  setSignalRConnected,
  signalrIsConnectedSelector,
  useAppDispatch,
  useAppSelector,
  vatsimTokenSelector,
} from "src/redux";
import { OVERRIDE_API_URL, OVERRIDE_CLIENT_HUB_URL, SIGNALR_IDLE_TIMEOUT_MS, VERSION, debugLog } from "src/utils";

const HubContext = createContext<HubConnection>(undefined!);

interface HubProviderProps {
  children: ReactNode;
}

export function HubProvider({ children }: Readonly<HubProviderProps>) {
  const vatsimToken = useAppSelector(vatsimTokenSelector);
  const nasToken = useAppSelector(nasTokenSelector)!;
  const environment = useAppSelector(environmentSelector);
  const signalrIsConnected = useAppSelector(signalrIsConnectedSelector);
  const session = useAppSelector(sessionSelector);
  const artccId = useAppSelector(artccDataSelector)?.id;
  const artccIdRef = useRef(artccId);
  const facilityId = useAppSelector(facilityIdSelector);
  const dispatch = useAppDispatch();

  const hubConnectionRef = useRef<HubConnection>(undefined!);
  const timeoutFunctionRef = useRef<NodeJS.Timeout>();

  async function subscribeToFeeds() {
    const hubConnection = hubConnectionRef.current;
    hubConnection.invoke("Subscribe", {
      Category: "FlightPlans",
      FacilityId: artccIdRef.current,
    });
    hubConnection.invoke("Subscribe", {
      Category: "OpenPositions",
      FacilityId: artccIdRef.current,
    });
  }

  useEffect(() => {
    if (artccId) {
      artccIdRef.current = artccId;
      subscribeToFeeds();
    }
  }, [artccId]);

  async function joinSession(sessionInfo: SessionInfoDto) {
    debugLog("Joining vNAS session");
    const hubConnection = hubConnectionRef.current;
    await hubConnection.invoke("JoinSession", {
      sessionId: sessionInfo.id,
      clientName: "vTDLS",
      clientVersion: VERSION,
      sysUid: "",
    });
    dispatch(setSession(plainToInstance(SessionInfoDto, sessionInfo)));
    dispatch(loadArtccData());
  }

  async function initializeSignalR(startConnection: boolean) {
    const hubConnection = hubConnectionRef.current;
    try {
      if (startConnection) {
        await hubConnection.start();
      }
      dispatch(setSignalRConnected());
      const sessions: SessionInfoDto[] = await hubConnection.invoke("GetSessions");
      const primarySession = plainToInstance(
        SessionInfoDto,
        sessions.find((s) => !s.isPseudoController),
      );
      if (primarySession) {
        debugLog("Received session info: ", primarySession);
        await joinSession(primarySession);
      }
    } catch (e) {
      debugLog(`Error connecting to server: ${e}`);
      toast.error(`Error connecting to server: ${e}`);
    }
  }

  async function getValidNasToken() {
    const decodedToken = decodeJwt(nasToken);
    if (tokenHasExpired(decodedToken)) {
      debugLog("Refreshing NAS token");
      if (!environment) {
        throw Error("Failed to refresh NAS token: environment is undefined");
      }
      if (!vatsimToken) {
        throw Error("Failed to refresh NAS token: VATSIM token is undefined");
      }
      const res = await refreshTokenAsync(OVERRIDE_API_URL ?? environment.apiBaseUrl, vatsimToken);
      return res.data!;
    }
    debugLog("Found NAS token");
    return nasToken;
  }

  function endSession() {
    dispatch(endSessionInState());
    dispatch(resetTdls());
    dispatch(resetUi());
  }

  useEffect(() => {
    if (signalrIsConnected && !facilityId) {
      timeoutFunctionRef.current = setTimeout(() => {
        hubConnectionRef.current.stop();
        dispatch(logout());
      }, SIGNALR_IDLE_TIMEOUT_MS);
    } else {
      clearTimeout(timeoutFunctionRef.current);
    }
  }, [signalrIsConnected, facilityId]);

  useEffect(() => {
    if (nasToken && !hubConnectionRef.current) {
      if (!environment) {
        throw Error("Failed to build hub connection: environment is undefined");
      }
      hubConnectionRef.current = new HubConnectionBuilder()
        .withUrl(OVERRIDE_CLIENT_HUB_URL ?? environment.clientHubUrl, {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => getValidNasToken(),
        })
        .withAutomaticReconnect()
        .build();
    } else if (!nasToken) {
      hubConnectionRef.current = undefined!;
    }
  }, [nasToken]);

  useEffect(() => {
    const hubConnection = hubConnectionRef.current;
    if (!hubConnection || signalrIsConnected) {
      return;
    }

    hubConnection.on("HandleSessionStarted", (sessionInfo) => joinSession(plainToInstance(SessionInfoDto, sessionInfo)));

    hubConnection.on("SetSessionActive", (isActive) => {
      debugLog(`Session is now ${isActive ? "active" : "inactive"}`);
      dispatch(setSessionIsActive(isActive));
    });

    hubConnection.on("HandleSessionEnded", () => {
      debugLog("Session ended");
      endSession();
    });

    hubConnection.on("ReceiveFlightPlans", (_, flightPlans: FlightPlan[]) => {
      debugLog("Received flight plans:", flightPlans);
      flightPlans.forEach((fp) => {
        if (fp.status === "Proposed") {
          fp.clearance = plainToInstance(Clearance, fp.clearance);
          dispatch(addOrUpdateFlightPlan(new TdlsFlightPlan(fp)));
        } else {
          dispatch(deleteFlightPlan(fp.aircraftId));
        }
      });
    });

    hubConnection.on("DeleteFlightPlans", (_, aircraftIds: string[]) => {
      debugLog(`Deleting flight plans for ${aircraftIds}`);
      aircraftIds.forEach((i) => {
        dispatch(deleteFlightPlan(i));
      });
    });

    hubConnection.on("ReceiveOpenPositions", (_, openPositions: OpenPositionDto[]) => {
      debugLog("Received open positions:", openPositions);
      dispatch(
        addOrUpdateOpenPositions(
          openPositions
            .filter(
              (p) =>
                p.positionId &&
                p.callsign !== session?.callsign &&
                p.isActive &&
                p.isPrimary &&
                p.role !== PositionRole.Observer &&
                p.facilityId &&
                (p.type === PositionType.Tracon || p.type === PositionType.Atct),
            )
            .map((p) => new OpenPosition(p)),
        ),
      );
      dispatch(deleteOpenPositions(openPositions.filter((p) => !p.isActive).map((p) => p.id)));
    });

    hubConnection.on("DeleteOpenPositions", (_, openPositions: string[]) => {
      debugLog(`Deleting open positions for ${openPositions}`);
      dispatch(deleteOpenPositions(openPositions));
    });

    hubConnection.on("HandleFsdConnectionStateChanged", (_, isConnected: boolean) => {
      debugLog(isConnected ? "FSD connection reestablished" : "FSD connection lost");
      if (isConnected) {
        toast.success("Successfully reconnected to FSD", { autoClose: 5000 });
        dispatch(setFsdIsConnected(true));
      } else {
        toast.error("Disconnected from FSD. Attempting to reconnect...");
        dispatch(setFsdIsConnected(false));
      }
    });

    hubConnection.onreconnecting((e) => {
      debugLog("Attempting to reconnect");
      toast.error(`${e?.message ?? ""} Attempting to reconnect...`.trim());
    });

    hubConnection.onreconnected(() => {
      (async () => {
        debugLog("Reconnected");
        dispatch(clearFlightPlans());
        dispatch(clearOpenPositions());
        await initializeSignalR(false);
        subscribeToFeeds();
        toast.dismiss();
        toast.success("Successfully reconnected to server", { autoClose: 5000 });
      })();
    });

    hubConnection.onclose((e) => {
      debugLog(`Disconnected: ${e}`);
      toast.error(e ? e.message : "Disconnected from server");
      endSession();
    });

    initializeSignalR(true);
  }, [nasToken]);

  return <HubContext.Provider value={hubConnectionRef.current}>{children}</HubContext.Provider>;
}

export const useHub = () => {
  return useContext(HubContext);
};

export default useHub;
